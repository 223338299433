import React, { useEffect, useCallback, useMemo } from 'react';
import { useField } from 'formik';
import { pathOr, propOr } from 'ramda';
import PropTypes from 'prop-types';

import {
  useOrganization,
  useCollectOrganizationsAction,
  ID,
  NAME,
  ORGANIZATION_KINDS,
  SUB_OPTICIAN,
  EXPAND,
  FIELDS,
  SPECIALTY,
  CONTACTS,
  ZIP_CODE,
  CITY,
  CANTON,
  ADDRESS,
} from 'store/organizations';
import { NURSING_HOME, OPTICIAN, SUB_ORGANIZATION, REPORTER, WARD } from 'store/patients';
import PaginationDropDown from 'components/PaginationDropDown';
import Checkbox from 'components/CheckboxNew';
import TextInput from 'components/TextInputNew';
import SelectedOrganization from 'components/SelectedOrganization';
import CreateComponent from '../CreateOrganizationItem';

const extraParams = { [EXPAND]: [SUB_OPTICIAN], [FIELDS]: [NAME, SPECIALTY, CONTACTS, ZIP_CODE, CITY, CANTON, ADDRESS] };
const nursingHomeKey = [NURSING_HOME, SUB_ORGANIZATION].join('.');
const reporterKey = [NURSING_HOME, REPORTER].join('.');
const opticianKey = [OPTICIAN, SUB_ORGANIZATION].join('.');
const wardKey = [NURSING_HOME, WARD].join('.');

const NursingHome = ({ readMode }) => {
  const getOrganizations = useCollectOrganizationsAction(ORGANIZATION_KINDS.NURSING_HOME, extraParams);

  const [{ value: nursingHome }, , { setValue: setNursingHome }] = useField(nursingHomeKey);
  const [{ value: reporter }, , { setValue: setReporter }] = useField(reporterKey);
  const [{ value: optician }, , { setValue: setOptician }] = useField(opticianKey);
  const [wardField] = useField(wardKey);
  const defaultOpticianId = useMemo(() => {
    if (optician || !nursingHome) return null;

    return pathOr(propOr(null, SUB_OPTICIAN, nursingHome || {}), [SUB_OPTICIAN, ID], nursingHome || {});
  }, [nursingHome, optician]);
  const { organization: defaultOptician } = useOrganization(defaultOpticianId);

  const handleClick = useCallback(() => {
    if (!readMode && nursingHome) setReporter(!reporter);
  }, [nursingHome, readMode, reporter, setReporter]);

  const shouldSetOptician = useMemo(
    () => Boolean(!optician && defaultOpticianId && defaultOptician),
    [defaultOpticianId, defaultOptician, optician]
  );

  const latestItem = useMemo(() => <CreateComponent kind={ORGANIZATION_KINDS.NURSING_HOME} />, []);

  const onChange = useCallback(
    (value) => {
      setNursingHome(value);

      if (!value) setReporter(false, false);
    },
    [setNursingHome, setReporter]
  );

  useEffect(() => {
    if (shouldSetOptician) setOptician(defaultOptician);
  }, [shouldSetOptician, defaultOptician, setOptician]);

  return (
    <>
      <Checkbox label="Erhält Korrespondenz" selected={reporter} onClick={handleClick} readMode={readMode || !nursingHome} />
      <PaginationDropDown
        idKey={ID}
        nameKey={NAME}
        action={getOrganizations}
        placeholder="Pflegezentrum suchen"
        value={nursingHome}
        onChange={onChange}
        selectedComponent={SelectedOrganization}
        latestItem={latestItem}
        disabled={readMode}
        onlyOne
      />
      <TextInput label="Station" {...wardField} readMode={readMode} mt={11} mb={0} />
    </>
  );
};

NursingHome.propTypes = {
  readMode: PropTypes.bool.isRequired,
};

export default NursingHome;
