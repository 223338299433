import React, { useCallback, useMemo } from 'react';
import { useFormikContext, useField } from 'formik';
import { pathOr, tail } from 'ramda';
import PropTypes from 'prop-types';

import { POSITION_TITLES, GENDER_RADIOS } from 'utils/constants';
import { REPRESENTATIVE } from 'store/planning';
import {
  REPORTER,
  TITLE,
  FIRST_NAME,
  LAST_NAME,
  GENDER,
  ADDRESS,
  CANTON,
  COUNTRY,
  ZIP_CODE,
  CITY,
  PHONE,
  EMAIL,
  INSTITUTION,
} from 'store/patients';
import TextInput from 'components/TextInputNew';
import Checkbox from 'components/CheckboxNew';
import Select from 'components/SelectNew';
import Radios from 'components/RadiosNew';
import AddressAutocomplete from 'components/AddressAutocomplete';
import ReverseButton from '../ReverseButton';

import { Inputs } from './styles';

const Contact = ({ disabled }) => {
  const getPath = useCallback((name) => [REPRESENTATIVE, name].join('.'), []);
  const { setValues, errors } = useFormikContext();
  const [{ value: reporter }, , { setValue: setReporter }] = useField(getPath(REPORTER));
  const [{ value: title }, , { setValue: setTitle }] = useField(getPath(TITLE));
  const [firstNameField] = useField(getPath(FIRST_NAME));
  const [lastNameField] = useField(getPath(LAST_NAME));
  const [institutionField] = useField(getPath(INSTITUTION));
  const [{ value: gender }, , { setValue: setGender }] = useField(getPath(GENDER));
  const [{ value: address }] = useField(getPath(ADDRESS));
  const [{ value: zipCode }] = useField(getPath(ZIP_CODE));
  const [{ value: city }] = useField(getPath(CITY));
  const [{ value: canton }] = useField(getPath(CANTON));
  const [{ value: country }] = useField(getPath(COUNTRY));
  const [phoneField] = useField(getPath(PHONE));
  const [emailField] = useField(getPath(EMAIL));
  const updateAutocompleteValues = useCallback(
    (data) => setValues(($) => ({ ...$, [REPRESENTATIVE]: { ...$[REPRESENTATIVE], ...data } })),
    [setValues]
  );

  const options = useMemo(() => (title ? POSITION_TITLES : tail(POSITION_TITLES)), [title]);

  const handleReporter = useCallback(() => setReporter(!reporter), [reporter, setReporter]);
  const reverseNames = useCallback(
    () =>
      setValues(($) => ({
        ...$,
        [REPRESENTATIVE]: {
          ...$[REPRESENTATIVE],
          [FIRST_NAME]: pathOr('', [REPRESENTATIVE, LAST_NAME], $),
          [LAST_NAME]: pathOr('', [REPRESENTATIVE, FIRST_NAME], $),
        },
      })),
    [setValues]
  );

  return (
    <>
      <Checkbox
        label="Patient hat einen gesetzlichen Vertreter"
        selected={reporter}
        onClick={handleReporter}
        readMode={disabled}
        noLabel
      />
      <Select label="Titel" value={title || ''} options={options} onSelect={setTitle} readMode={disabled} />
      <Inputs $withoutButton={disabled}>
        <TextInput label="Vorname" {...firstNameField} readMode={disabled} />
        <TextInput label="Nachname" {...lastNameField} readMode={disabled} />
        {!disabled && <ReverseButton onClick={reverseNames} />}
      </Inputs>
      <TextInput label="Institution" {...institutionField} error={errors[institutionField.name]} readMode={disabled} />
      <Radios value={gender} label="Geschlecht" items={GENDER_RADIOS} setValue={setGender} readMode={disabled} />
      <AddressAutocomplete
        address={address}
        zipCode={zipCode}
        city={city}
        canton={canton}
        country={country}
        setData={updateAutocompleteValues}
        zipCodeErr={errors[`${REPRESENTATIVE}.${ZIP_CODE}`]}
        readMode={disabled}
      />
      <TextInput label="Telefon" {...phoneField} readMode={disabled} />
      <TextInput label="E-Mail" {...emailField} error={errors[emailField.name]} readMode={disabled} />
    </>
  );
};

Contact.defaultProps = { disabled: false };
Contact.propTypes = { disabled: PropTypes.bool };

export default Contact;
