export const exteriorEyeQuestions = [
  {
    key: 'Lidstellung',
    value: ['Korrekt', 'Entropium', 'Ektropium', 'Auffällig (siehe Kommentar)', 'Nicht berurteilbar'],
    prop: 'eyelidPosition',
  },
  {
    key: 'Lidaspekt',
    value: ['Unauffällig', 'Leichte Blepharitis', 'Starke Blepharitis', 'Auffällig (siehe Kommentar)', 'Nicht berurteilbar'],
    prop: 'eyelidAspect',
  },
  {
    key: 'Falls Blepharitis',
    value: [
      'Ohne Sekret',
      'Ohne Sekret aber mit Verkrustungen',
      'Mit eitrigem Sekret',
      'Mit schleimigem Sekret',
      'Nicht berurteilbar',
    ],
    prop: 'blepharitis',
  },
  {
    key: 'Konjunktiva',
    value: ['Reizlos', 'Reizarm', 'Injiziert', 'Stark injiziert', 'Auffällig (siehe Kommentar)'],
    prop: 'konjunktiva',
  },
  {
    key: 'Hornhaut',
    value: ['Klar und glatt', 'Matter Aspekt, scheint klar', 'Trübung', 'Auffällig (siehe Kommentar)'],
    prop: 'cornea',
  },
  {
    key: 'Vorderkammer',
    value: ['Unauffällig', 'Mitteltief', 'Flach', 'Nicht beurteilbar', 'Auffällig (siehe Kommentar)'],
    prop: 'anteriorChamber',
  },
  {
    key: 'Iris',
    value: [
      'Unauffällig',
      'Rubeosis sichtbar',
      'Transillumination / Pigmentlücken',
      'Auffällig (siehe Kommentar)',
      'Nicht berurteilbar',
    ],
    prop: 'iris',
  },
  {
    key: 'Pupille',
    value: ['Rund', 'Entrundet', 'Auffällig (siehe Kommentar)', 'Nicht berurteilbar'],
    prop: 'pupil',
  },
  {
    key: 'Linse',
    value: [
      'Pseudophakie',
      'Pseudophakie, Nachstar',
      'Katarakt vorhanden',
      'Dichte Katarakt',
      'Klar',
      'Altersentsprechend',
      'Schichtbetont',
      'Nicht berurteilbar',
    ],
    prop: 'lens',
  },
  {
    key: 'Qualität der Aufnahme',
    value: ['Gut', 'Brauchbar', 'Unscharf', 'Leicht unscharf', 'Nicht beurteilbar'],
    prop: 'imageQuality',
  },
];

export const fundusEyeQuestions = [
  {
    key: 'Einblick',
    value: ['Gut', 'Verschwommen', 'Leicht verschwommen', 'Sehr schlecht', 'Keine Details zu erkennen'],
    prop: 'view',
  },
  {
    key: 'Peripapilläre Atrophie',
    value: ['Nicht vorhanden', 'Leicht vorhanden, Unauffällig', 'Ausgeprägt', 'Ergänzung (siehe Kommentar)', 'Nicht beurteilbar'],
    prop: 'atrophy',
  },
];

export const maculaQuestions = [
  'Unauffällig',
  'Schöner Zentralreflex',
  'Stumpfer Aspekt ohne Reflexe',
  'Etwas unstrukturiert',
  'Harte Drusen',
  'Weiche Drusen',
  'Kleine Atrophiebereiche',
  'Geografische Atrophie',
  'Subretinale Blutung',
  'Präretinale Blutung',
  'Vd.a. CNV',
  'Fibrovaskuläre Narbe',
  'Undefinierbare Narbe',
  'Cellophanaspekt',
  'Epiretinale Fibroplasie',
  'Red Spots in der Fovea',
  'Gemischte Drusen',
  'Drusen und Pigmentverschiebungen',
  'Red Spots / kleinere Blutungen perifoveal',
  'Harte Exsudate weniger als 500 μm von Fovea entfernt',
  'Harte Exsudate mehr als 500 μm von Fovea entfernt',
  'Patternartige Pigmentverschiebungen',
  'Nicht beurteilbar',
  'Keine diabetischen Veränderungen',
];

export const retinaPerimeterQuestions = [
  'Normale Gefässe',
  'Unauffällig',
  'Retikuläres Alterspigment',
  'Sehr enge Arterien',
  'Silberdrahtreflexe',
  'Vermehrte Schlängelung der Gefässe',
  'Vermehrte Schlängelung der Venen',
  'Kreuzungszeichen',
  'Gefäss-Einscheidungen',
  'Ghost vessels',
  'Präretinale Fleckblutungen',
  'Mikroaneurysmen / Blutungen leicht ausgeprägt',
  'Mikroanurysmen / Blutungen mässig ausgeprägt',
  'Mikroanuerysmen / Blutungen schwer ausgeprägt in 2-3 Zonen',
  'Mikroanuerysmen / Blutungen schwer ausgeprägt in 4 Zonen, IRMAs wenig vorhanden',
  'IRMAs ausgeprägt vorhanden',
  'Venous beading in 1 Quadrant',
  'Venous beading in 2 oder mehr Quadranten',
  'Verdacht auf Neovaskularisation der Papille (NVD)',
  'Verdacht auf Neovaskularistion der Periferie (NVE)',
  'GK-Blutung',
  'Proliferation fibrösen Gewebes',
  'Aderhautnävus',
  'Pigmentepithelhyperplasie',
  'Pigmentepithelhypertrophie',
  'Vd.a. perifere CNV',
  'Keine diabetischen Veränderungen',
  'Nicht beurteilbar',
];

export const papilleQuestions = [
  'Vital',
  'Blässlich',
  'Blass',
  'Randscharf',
  'Leicht prominent aber randscharf',
  'Unscharf',
  'Nicht tilted',
  'Leicht tilted',
  'Tilted',
  'Physiologisch exkaviert',
  'Glaukomatös exkaviert',
  'nicht exkaviert',
  '0.1 exkaviert',
  '0.2 exkaviert',
  '0.3 exkaviert',
  '0.4 exkaviert',
  '0.5 exkaviert',
  '0.6 exkaviert',
  '0.7 exkaviert',
  '0.8 exkaviert',
  '0.9 exkaviert',
  'Randständig exkaviert',
  'Auffällig (siehe Kommentar)',
  'Nicht beurteilbar',
];
