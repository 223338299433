import {
  FIRST_NAME,
  LAST_NAME,
  GENDER,
  BIRTH,
  ADDRESS,
  CARE_OF,
  CANTON,
  COUNTRY,
  ZIP_CODE,
  CITY,
  PHONE,
  EMAIL,
  NATIONAL,
  SUB_COMPANY,
  // NUMBER,
  // VEKA,
  MODEL,
  SUPPLEMENT,
  SSN,
  REPORTER,
  WARD,
  TITLE,
  NURSING_HOME,
  INSTITUTION,
  INSURANCE,
  OTHER_CONTACTS,
  AGENT,
  FAMILY_DOCTOR,
  EYE_DOCTOR,
  OPTICIAN,
  GUARANTY,
  SUB_ORGANIZATION,
  NO_REPORT,
  PORTRAIT,
  REMUNERATION,
  TIERS_PAYANT,
  LAW_TYPE,
  LAW_TYPE_KINDS,
  IS_EMERGENCY,
} from 'store/patients';

export const MODELS = {
  MANAGED_CARE: 'Managed Care',
  OTHER: 'Anderes',
};

export const contactValues = {
  [REPORTER]: false,
  [IS_EMERGENCY]: false,
  [TITLE]: '',
  [FIRST_NAME]: '',
  [LAST_NAME]: '',
  [INSTITUTION]: '',
  [GENDER]: '',
  [ADDRESS]: '',
  [CANTON]: '',
  [COUNTRY]: 'CH',
  [ZIP_CODE]: '',
  [CITY]: '',
  [PHONE]: '',
  [EMAIL]: '',
};

export const defaultValues = {
  [TITLE]: '',
  [FIRST_NAME]: '',
  [LAST_NAME]: '',
  [BIRTH]: null,
  [GENDER]: '',
  [ADDRESS]: '',
  [CARE_OF]: '',
  [CANTON]: '',
  [COUNTRY]: 'CH',
  [ZIP_CODE]: '',
  [CITY]: '',
  [PHONE]: '',
  [EMAIL]: '',
  [NATIONAL]: 'CH',
  [PORTRAIT]: '',
  [NURSING_HOME]: {
    [SUB_ORGANIZATION]: null,
    [REPORTER]: false,
    [WARD]: '',
  },
  [OPTICIAN]: {
    [SUB_ORGANIZATION]: null,
    [REPORTER]: false,
    [GUARANTY]: false,
  },
  [INSURANCE]: {
    [SUB_COMPANY]: null,
    // [NUMBER]: '',
    // [VEKA]: '',
    [MODEL]: '',
    [SUPPLEMENT]: null,
    [SSN]: '',
    [REMUNERATION]: TIERS_PAYANT,
    [LAW_TYPE]: LAW_TYPE_KINDS.KVG,
  },
  [AGENT]: { ...contactValues },
  [OTHER_CONTACTS]: [],
  [FAMILY_DOCTOR]: {
    [SUB_ORGANIZATION]: null,
    [REPORTER]: false,
  },
  [EYE_DOCTOR]: {
    [SUB_ORGANIZATION]: null,
    [REPORTER]: false,
  },
  [NO_REPORT]: false,
};
